import React, { useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiPostCall } from "../Functions/connector";
import { GetProducts } from "../Functions/CommonApiCall";
import {
  ChangeCountryFunction,
  getNineYear,
  getSmallestDate,
  setDatesFunction,
  termServiceFunction,
} from "../Helper/HelperFun";
import MultipleEquipment from "./MultipleEquipment";
import { FillData, ValidateBundleData } from "./BundleOrderFun";
import MultipleDate from "./MultipleDate";
import LPPopup from "../LocationPortalComp/LPPopup";
import POPopup from "../LocationPortalComp/POPopup";
import $ from "jquery";

export default function BundleOrderPage() {
  const { subLinkId } = useParams();
  const [SubLinkSignUpRepInfoData, setSubLinkSignUpRepInfoData] = useState({
    subLink: [],
    signUpRepInfo: [],
  });
  const [BundleSubLinkData, setBundleSubLinkData] = useState([]);
  const [BundleData, setBundleData] = useState([]);
  // const [InsuranceData, setInsuranceData] = useState({
  //   Visible: false,
  //   InnerText: "",
  //   InnerHtml: "",
  //   Checkbox: false,
  //   RadioBtn: "",
  // });
  const [TermService, setTermService] = useState("");
  const [BeginEndDate, setBeginEndDate] = useState({
    MultipleDatesData: [],
    StartDateValue: "",
    EndDateValue: "",
    isStartDateVisible: true,
    isEndDateVisible: true,
    MultipleDates: true,
    DepartureDate: "",
  });
  const [CountryData, SetCountryData] = useState({
    StateList: [],
    ShowState: false,
    ShowZipCode: false,
  });
  const [DeliveryShippingData, SetDeliveryShippingData] = useState({
    isVisible: false,
    ShippingStateList: [],
    ShippingShowState: false,
    ShippingShowZipCode: false,
    ShippingShowDate: false,
    ShippingMethod: "UseBillingAddress",
    Accessory: [],
  });

  const [card, setCard] = useState("");
  const [Year, SetYear] = useState([]);

  const [Equipment, setEquipment] = useState({
    EquipmentCode: "",
    kntCountryCode: "",
    smssCode: "",
    Insurance: false,
    MultipleEquipmentsData: [],
    Quantity: 1,
    ShowShipping: false,
    MultipleEquipments: true,
  });

  const [ErrorMsg, SetErrorMsg] = useState({
    err_visible: false,
    message: "",
  });

  const [successData, setSuccessData] = useState({ loader: false, result: {} });
  const [show, setShow] = useState(false);
  console.log("subLinkId",subLinkId);
  useLayoutEffect(() => {
    SetYear(getNineYear());
    var urlencoded = new URLSearchParams();
    urlencoded.append("SubLink", subLinkId);
    ApiPostCall("/RouterParserBundleOrder/GetSubLinkInfo", urlencoded).then(
      (result) => {
        try {
          if (result === undefined || result === "") {
            alert("Something went wrong");
          } else {
            const responseRs = JSON.parse(result);
            if (responseRs?.Status != "Error") {
              setSubLinkSignUpRepInfoData({
                subLink: JSON.parse(responseRs.Data?.BundleData)[0],
                signUpRepInfo: JSON.parse(
                  responseRs.Data?.SignUpRepInfoData
                )[0],
              });
              // setShipData(JSON.parse(responseRs.Data?.ShipData)[0]);
            }
          }
        } catch (e) {
          console.log(e);
        } finally {
          //   setLoader(false);
        }
      }
    );
  }, []);
  const SelectBundle = (e) => {
    if (e != "") {
      setBundleData([]);
      GetProducts(subLinkId, e).then((result) => {
        try {
          if (result === undefined || result === "") {
            alert("Something went wrong");
          } else {
            const responseRs = result;
            setBundleData(responseRs[0]?.bundles[0]);
            setBundleSubLinkData(responseRs);
            // insuranceFun(responseRs[0]);
            setTermService(termServiceFunction(responseRs[0]?.minimumPeriod));
            const {
              StartDateValue,
              EndDateValue,
              isStartDateVisible,
              isEndDateVisible,
            } = setDatesFunction(responseRs[0]);
            setBeginEndDate({
              ...BeginEndDate,
              StartDateValue: StartDateValue,
              EndDateValue: EndDateValue,
              isStartDateVisible: isStartDateVisible,
              isEndDateVisible: isEndDateVisible,
            });
            console.log(result);
          }
        } catch (e) {
          console.log(e);
        } finally {
          //   setLoader(false);
        }
      });
    }
  };
  // const insuranceFun = (data) => {
  //   const { insurancePrice, insurance, insurancePriceText, insuranceDesc } =
  //     data;

  //   var sPrice;
  //   var sText = "";
  //   if (insurancePrice == null) {
  //     sPrice = "";
  //     sText = "";
  //   } else if (insurancePrice > 1) {
  //     sPrice = "$" + insurancePrice;
  //     sText = sPrice + insurancePriceText + " " + insuranceDesc;
  //   } else {
  //     sPrice = insurancePrice * 100 + "¢ ";
  //     sText = sPrice + insurancePriceText + " " + insuranceDesc;
  //   }
  //   var insuranceObj = new Object();
  //   insuranceObj.Visible = true;
  //   insuranceObj.InnerText = "Insurance: ";

  //   switch (insurance) {
  //     case "not_relevant":
  //       insuranceObj.Visible = false;
  //       break;

  //     case "included":
  //       insuranceObj.Checkbox = true;
  //       sText = "Included! " + sText;
  //       insuranceObj.InnerHtml = sText;
  //       break;

  //     case "required":
  //       insuranceObj.Checkbox = true;
  //       sText = "Required! " + sText;
  //       insuranceObj.InnerHtml = sText;
  //       break;

  //     case "optional":
  //       insuranceObj.Checkbox = false;
  //       insuranceObj.InnerHtml = sText;
  //       insuranceObj.InnerText = "Do you want insurance?: ";
  //       break;
  //   }

  //   setInsuranceData({
  //     Visible: insuranceObj?.Visible,
  //     InnerText: insuranceObj?.InnerText,
  //     InnerHtml: insuranceObj?.InnerHtml,
  //     Checkbox: insuranceObj?.Checkbox,
  //   });
  // };

  const ChangeCountry = (e) => {
    const { StateList, ShowState, ShowZipCode } = ChangeCountryFunction(
      e,
      BundleSubLinkData[0]
    );
    SetCountryData({
      ...CountryData,
      StateList: StateList,
      ShowState: ShowState,
      ShowZipCode: ShowZipCode,
    });
  };

  const selectShipping = (e) => {
    var countryId =
      e.target.options[e.target.selectedIndex].getAttribute("countrie-id");
    var optRequireShipAddress = e.target.options[
      e.target.selectedIndex
    ].getAttribute("opt-require-ship-address");
    optRequireShipAddress = optRequireShipAddress == "1" ? true : false;
    SetDeliveryShippingData({
      ...DeliveryShippingData,
      isVisible: optRequireShipAddress,
      ShippingShowDate: optRequireShipAddress,
      ...(countryId == "4"
        ? { ShippingShowZipCode: false }
        : {
            ShippingShowZipCode: true,
          }),
      ...(countryId == "1" || countryId == "7"
        ? {
            ShippingShowState: true,
            ShippingStateList:
              countryId == "1"
                ? BundleSubLinkData[0]?.UsaStates
                : BundleSubLinkData[0]?.CanadaStates,
          }
        : { ShippingShowState: false, ShippingStateList: [] }),
      Accessory: BundleSubLinkData[0]?.shippings?.filter(
        (obj) => obj.shippingID == e.target.value
      )[0]?.shippingAccessories,
    });
  };
  // const selectEquipment = (e) => {
  //   var BaseCode =
  //     e.target.options[e.target.selectedIndex].getAttribute("data-code");
  //   if (Equipment?.MultipleEquipments) {
  //     setEquipment({
  //       ...Equipment,
  //       EquipmentCode: BaseCode,
  //       ShowShipping: BaseCode == "9999" || BaseCode == "3290" ? false : true,
  //     });
  //   }
  // };

  const SubmitOrder = (e, BundleSubLinkData) => {
    e.preventDefault();

    Validation(e.target, BundleSubLinkData).then((result) => {
      if (!result) {
        let data = FillData(
          e,
          BundleSubLinkData,
          Equipment,
          DeliveryShippingData,
          BeginEndDate
        );
        if (data != null && data.length > 0) {
          // console.log(data);
          setShow(true);
          var raw = JSON.stringify(data);
          // console.log(raw);
          data[0].bitUSA_SIM_Order= true;
          data[0].USA_SIM_Order= true;
          data[0].KNTRequired= "99";


          setSuccessData({ ...successData, loader: true });
          $.post(
            "https://talknsave.net/wp-content/themes/betheme-child/LocationPortalOrder.php",
            {
              SaveApiData: raw,
            },
            function (msg) {
              try {
                let result = JSON.parse(msg);
                setSuccessData({
                  ...successData,
                  loader: false,
                  result: result,
                });
                console.log(msg);
              } catch (error) {
                console.log(error);
              }
              // finally {
              //   setSuccessData({ ...successData, loader: false });
              // }
            }
          );
        }
      }
    });
  };
  const Validation = async (data, bundleSubLinkData) => {
    let { isDateVisible, err_date_msg } = await ValidateBundleData(
      data,
      bundleSubLinkData,
      Equipment,
      DeliveryShippingData,
      BeginEndDate
    );
    console.log(isDateVisible, err_date_msg);
    SetErrorMsg({
      err_visible: isDateVisible,
      message: err_date_msg,
    });
    return isDateVisible;
  };

  const CopyDetails = (e) => {
    if (e) {
      document.getElementsByName("billingFirstName")[0].value =
        document.getElementsByName("FirstName")[0].value;
      document.getElementsByName("billingLastName")[0].value =
        document.getElementsByName("lastName")[0].value;
      document.getElementsByName("billingEmail")[0].value =
        document.getElementsByName("email")[0].value;
    }
  };

  return (
    <React.Fragment>
      <div className="PageMargin">
        <form onSubmit={(e) => SubmitOrder(e, BundleSubLinkData)}>
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="col-12 ro-font1 ">Plan Details</div>
              <div className="col-12 mb-3 mt-4">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-4 col-4 align-self-center">
                      <label>
                        Agent Name <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="col-md-8 col-8">
                      <select
                        className="form-select select mb-0"
                        name="signUpRepInfo"
                        id=""
                        // onChange={(e) => SelectBundle(e.target.value)}
                      >
                        <option value="0">Please select</option>
                        {SubLinkSignUpRepInfoData?.signUpRepInfo?.map(
                          (data, i) => {
                            return (
                              <>
                                <option value={data?.SignupRepCode}>
                                  {data?.SignupRepName}
                                </option>
                              </>
                            );
                          }
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 my-3">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-4 col-4 align-self-center">
                      <label>
                        Bundle <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="col-md-8 col-8">
                      <select
                        className="form-select select mb-0"
                        name="BundleData"
                        id=""
                        onChange={(e) => SelectBundle(e.target.value)}
                      >
                        <option value="-1">Select a plan</option>
                        {SubLinkSignUpRepInfoData?.subLink?.map((data, i) => {
                          return (
                            <>
                              <option value={data?.Counter}>
                                {data?.BundleName.replace(
                                  "<br />",
                                  " - "
                                ).replace("<br>", " ")}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              {BundleSubLinkData.length > 0 && (
                <>
                  <div className="col-12 my-3">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-4 col-4 align-self-center">
                          <label>
                            Equipment Quantity
                            <span className="text-danger"> *</span>
                          </label>
                        </div>
                        <div className="col-md-8 col-8">
                          <select
                            className="form-select select mb-0"
                            name="EquipmentQuantity"
                            id=""
                            onChange={(e) =>
                              setEquipment({
                                ...Equipment,
                                Quantity: parseInt(e.target.value),
                                ...(parseInt(e.target.value) == 1 && {
                                  MultipleEquipments: true,
                                }),
                              })
                            }
                          >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* {Equipment?.MultipleEquipments ? (
                    <>
                      
                      {Equipment.EquipmentCode == "9999" && (
                        <AlreadyHaveSimQuantity
                          Quantity={Equipment.Quantity}
                          Equipment={Equipment}
                          setEquipment={setEquipment}
                        />
                      )}
                    </>
                  ) : (
                    <> */}
                  <MultipleEquipment
                    BundleData={BundleData}
                    Equipment={Equipment}
                    setEquipment={setEquipment}
                  />
                  {/* </>
                  )} */}
                  {Equipment?.Quantity != 1 && (
                    <div className="row my-2">
                      <div className="col-md-4 col-4"></div>
                      <div className="col-md-8 col-8">
                        <div className="form-check">
                          <label
                            className="form-check-label ro-font2 c142454"
                            htmlFor="flexCheckDefault"
                          >
                            Save this choice for the rest of the products
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                            name=""
                            defaultChecked={Equipment?.MultipleEquipments}
                            onChange={(e) => {
                              setEquipment({
                                ...Equipment,
                                EquipmentCode: "",
                                MultipleEquipments: e.target.checked,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {/* {BundleSubLinkData[0]?.optAllowMultipleRentals && (
                    <>
                      <div className="col-12 my-3">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-4 col-4 align-self-center">
                              <label>
                                Equipment Quantity
                                <span className="text-danger"> *</span>
                              </label>
                            </div>
                            <div className="col-md-8 col-8">
                              <select
                                className="form-select select mb-0"
                                name="EquipmentQuantity"
                                id=""
                                onChange={(e) =>
                                  setEquipment({
                                    ...Equipment,
                                    Quantity: parseInt(e.target.value),
                                  })
                                }
                              >
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )} */}

                  {/* {InsuranceData.Visible && (
                    <>
                      <div className="col-12 my-3">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-4 col-4 align-self-center">
                              <label>{InsuranceData.InnerText}</label>
                            </div>
                            <div className="col-md-8 col-8">
                              {InsuranceData.Checkbox ? (
                                <>
                                  <div className="row my-2">
                                    <div className="col-12">
                                      <div className="form-check">
                                        <label
                                          className="form-check-label ro-font2 c142454"
                                          htmlFor="flexCheckDefault"
                                        >
                                          {InsuranceData.InnerHtml}
                                        </label>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="flexCheckDefault"
                                          name=""
                                          checked
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="row my-2">
                                    <div className="col-12 c142454 ro-font2">
                                      {InsuranceData.InnerHtml}
                                    </div>
                                  </div>
                                  <div
                                    className="d-flex"
                                    onChange={(e) =>
                                      setInsuranceData({
                                        ...InsuranceData,
                                        RadioBtn: e.target.id,
                                      })
                                    }
                                  >
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="InsuranceRadio"
                                        id="InsuranceRadioYes"
                                        value={InsuranceData.RadioBtn}
                                        defaultChecked={
                                          InsuranceData.RadioBtn ===
                                            "InsuranceRadioYes" || ""
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="flexRadioDefault1"
                                      >
                                        Yes
                                      </label>
                                    </div>
                                    <div className="ms-2 form-check">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="InsuranceRadio"
                                        id="InsuranceRadioNo"
                                        value={InsuranceData.RadioBtn}
                                        defaultChecked={
                                          InsuranceData.RadioBtn ===
                                            "InsuranceRadioNo" || ""
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="flexRadioDefault2"
                                      >
                                        No
                                      </label>
                                    </div>
                                  </div>
                                  {InsuranceData.RadioBtn ==
                                    "InsuranceRadioNo" && (
                                    <>
                                      <div className="row my-2">
                                        <div className="col-12">
                                          <div className="form-check">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              value=""
                                              id="flexCheckDefault"
                                              name="NoInsuranceConfirmationCB"
                                            />
                                            <label
                                              className="form-check-label ro-font2 text-danger"
                                              htmlFor="flexCheckDefault"
                                            >
                                              I Acknowledge the fact that I have
                                              declined insurance coverage.
                                              <br /> In the case of damage to
                                              the phone, it will be repaired at
                                              my own expense.
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )} */}

                  <div className="col-12 my-5">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-4 col-4 align-self-center">
                          <label>
                            Term of Services
                            <span className="text-danger"> *</span>
                          </label>
                        </div>
                        <div className="col-md-8 col-8">
                          <div className="row">
                            <div className="col-12  ro-font3">
                              {TermService}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 ro-font1 mt-2">User Details</div>

                  <MultipleDate
                    bundleData={BundleSubLinkData[0]}
                    BeginEndDate={BeginEndDate}
                    setBeginEndDate={setBeginEndDate}
                    Quantity={Equipment?.Quantity}
                  />

                  {Equipment?.Quantity != 1 && (
                    <div className="row my-2">
                      <div className="col-md-4 col-4"></div>
                      <div className="col-md-8 col-8">
                        <div className="form-check">
                          <label
                            className="form-check-label ro-font2 c142454"
                            htmlFor="flexCheckDefault"
                          >
                            Save this choice for the rest of the products
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                            name=""
                            defaultChecked={BeginEndDate?.MultipleDates}
                            onChange={(e) => {
                              setBeginEndDate({
                                ...BeginEndDate,
                                MultipleDates: e.target.checked,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-12 my-3">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-4 col-4 align-self-center">
                          <label>
                            First Name
                            <span className="text-danger"> *</span>
                          </label>
                        </div>
                        <div className="col-md-8 col-8">
                          <input
                            className="box w-100"
                            name="FirstName"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-3">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-4 col-4 align-self-center">
                          <label>
                            Last Name
                            <span className="text-danger"> *</span>
                          </label>
                        </div>
                        <div className="col-md-8 col-8">
                          <input
                            className="box w-100"
                            name="lastName"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-3">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-4 col-4 align-self-center">
                          <label>
                            Email<span className="text-danger"> *</span>
                          </label>
                        </div>
                        <div className="col-md-8 col-8">
                          <input
                            className="box w-100"
                            name="email"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {BundleSubLinkData[0]?.optRequestProgramName && (
                    <>
                      <div className="col-12 my-3">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-4 col-4 align-self-center">
                              <label>
                                Name of Israel <br />
                                Program
                                <span className="text-danger"> *</span>
                              </label>
                            </div>
                            <div className="col-md-8 col-8">
                              <input
                                className="box w-100"
                                name="nameOfIsraelProgram"
                                type="text"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="col-12 my-3">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-4 col-4 align-self-center">
                          <label
                            className="form-check-label ro-font2 c142454"
                            htmlFor="flexCheckDefault"
                          >
                            Please do not include international prefixes (001
                            for US etc.)
                          </label>
                          <label>
                            Home Phone Number
                            <span className="text-danger"> *</span>
                          </label>
                        </div>
                        <div className="col-md-8 col-8">
                          <input
                            className="box w-100"
                            name="phoneNumber"
                            type="number"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-3">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-4 col-4 align-self-center">
                          <label>
                            Street Address
                            <span className="text-danger"> *</span>
                          </label>
                        </div>
                        <div className="col-md-8 col-8">
                          <input
                            className="box w-100"
                            name="streetAddress"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-3">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-4 col-4 align-self-center">
                          <label>
                            City <span className="text-danger"> *</span>
                          </label>
                        </div>
                        <div className="col-md-8 col-8">
                          <input
                            className="box w-100"
                            name="city"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-3">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-4 col-4 align-self-center">
                          <label>
                            Country <span className="text-danger"> *</span>
                          </label>
                        </div>
                        <div className="col-md-8 col-8">
                          <select
                            className="form-select select mb-0"
                            name="CountryData"
                            id=""
                            onChange={(e) => ChangeCountry(e.target.value)}
                          >
                            <option value="-1">Please select</option>
                            {BundleSubLinkData[0]?.countries?.map((data, i) => {
                              return (
                                <>
                                  <option
                                    key={data?.CountryID}
                                    value={data?.CountryID}
                                    data-countryname={data?.CountryName}
                                  >
                                    {data?.CountryName}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  {CountryData?.ShowState && (
                    <>
                      <div className="col-12 my-3">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-4 col-4 align-self-center">
                              <label>
                                State/Province
                                <span className="text-danger"> *</span>
                              </label>
                            </div>
                            <div className="col-md-8 col-8">
                              <select
                                className="form-select select mb-0"
                                name="StateData"
                                id=""
                              >
                                <option value="-1">Please select</option>
                                {CountryData?.StateList.map((data, i) => {
                                  return (
                                    <>
                                      <option
                                        key={data?.StateCode}
                                        value={data?.StateCode}
                                      >
                                        {data?.StateName}
                                      </option>
                                    </>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {CountryData?.ShowZipCode && (
                    <>
                      <div className="col-12 my-3">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-4 col-4 align-self-center">
                              <label>ZIP/Postal Code</label>
                            </div>
                            <div className="col-md-8 col-8">
                              <input
                                className="box w-100"
                                name="ZipCode"
                                type="text"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {BundleSubLinkData[0]?.AdminComment == "BHLT" && (
                    <>
                      <div className="col-12 my-3">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-4 col-4 align-self-center">
                              <label>
                                Talmid's Name
                                <span className="text-danger"> *</span>
                              </label>
                            </div>
                            <div className="col-md-8 col-8">
                              <input
                                className="box w-100"
                                name="TalmidName"
                                type="text"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 my-3">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-4 col-4 align-self-center">
                              <label>
                                Father's Name
                                <span className="text-danger"> *</span>
                              </label>
                            </div>
                            <div className="col-md-8 col-8">
                              <input
                                className="box w-100"
                                name="FatherName"
                                type="text"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 my-3">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-4 col-4 align-self-center">
                              <label>
                                Name of Yeshiva in the US{" "}
                                <span className="text-danger"> *</span>
                              </label>
                            </div>
                            <div className="col-md-8 col-8">
                              <input
                                className="box w-100"
                                name="YeshivaName"
                                type="text"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 my-3">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-4 col-4 align-self-center">
                              <label>
                                Name of His Rabbi in the US
                                <span className="text-danger"> *</span>
                              </label>
                            </div>
                            <div className="col-md-8 col-8">
                              <input
                                className="box w-100"
                                name="RabbiName"
                                type="text"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 my-3">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-4 col-4 align-self-center">
                              <label>
                                The Yeshiva you will be attending in
                                Israel(optional).
                              </label>
                            </div>
                            <div className="col-md-8 col-8">
                              <select
                                className="form-select select mb-0"
                                name="YeshivaSelect"
                                id=""
                              >
                                <option value="I am not sure">
                                  I am not sure
                                </option>
                                <option value="Brisk">Brisk</option>
                                <option value="Kollel Tiferes Tzvi (Rabbi M.A. Rosengarten)">
                                  Kollel Tiferes Tzvi (Rabbi M.A. Rosengarten)
                                </option>
                                <option value="Mir">Mir</option>
                                <option value="Ner Moshe (R’ Shalom Schechter)">
                                  Ner Moshe (R’ Shalom Schechter)
                                </option>
                                <option value="Tiferes Chaim (Pragers)">
                                  Tiferes Chaim (Pragers)
                                </option>
                                <option value="Yagdil">Yagdil</option>
                                <option value="Yeshivas HaGramad (Reb Dovid)">
                                  Yeshivas HaGramad (Reb Dovid)
                                </option>
                                <option value="Zos L’Yaakov (Rabbi Y.D. Shechter)">
                                  Zos L’Yaakov (Rabbi Y.D. Shechter)
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {Equipment?.ShowShipping && (
                    <>
                      <div className="col-12 ro-font1 mt-5">Shipping</div>
                      <div className="col-12 my-3">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-4 col-4 align-self-center">
                              <label>
                                Shipping <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="col-md-8 col-8">
                              <select
                                className="form-select select mb-0"
                                name="shipping"
                                id=""
                                onChange={selectShipping}
                              >
                                <option value="-2">Please select</option>
                                {BundleSubLinkData[0]?.shippings?.map(
                                  (data, i) => {
                                    var hideShipping =
                                      (Equipment?.MultipleEquipmentsData?.every(
                                        (obj) => obj.EquipmentBaseCode == "3300"
                                      ) &&
                                      (data?.basecode == "5152" ||
                                        data?.countryName == "USA") || Equipment?.MultipleEquipmentsData?.every(
                                          (obj) => obj.EquipmentBaseCode == "3370"
                                        ) &&
                                        (data?.countryName == "USA") &&
                                        (subLinkId == "17909" || subLinkId == "17886" || subLinkId == "19165") || Equipment?.MultipleEquipmentsData?.every(
                                          (obj) => obj.EquipmentBaseCode == "3030"
                                        ) &&
                                        (data?.countryName == "USA"));
                                    return (
                                      <>
                                        {data?.shippingID == -1 ? (
                                          <optgroup
                                            className={
                                              hideShipping ? "d-none" : ""
                                            }
                                            label={data?.countryName}
                                          ></optgroup>
                                        ) : (
                                          <>
                                            <option
                                              className={
                                                hideShipping ? "d-none" : ""
                                              }
                                              value={data?.shippingID}
                                              base-code={
                                                data?.basecode
                                                  ? data?.basecode
                                                  : ""
                                              }
                                              countrie-id={data?.countryId}
                                              countrie-name={data?.countryName}
                                              has-mifi={data?.HasMifi ? 1 : 0}
                                              has-netstick={
                                                data?.HasNetstick ? 1 : 0
                                              }
                                              opt-require-ship-address={
                                                data?.optRequireShipAddress
                                              }
                                              opt-local-pickup={
                                                data?.optLocalPickup
                                              }
                                              shipmethod={
                                                data?.shippingMethodNEW
                                              }
                                              has-phones={
                                                data?.hasPhones ? 1 : 0
                                              }
                                              shipping-notes={
                                                data?.shippingNotes
                                              }
                                              data-cost={data?.cost}
                                              data-title={data?.shippingName}
                                            >
                                              {data?.shippingName}{" "}
                                              {data?.cost == 0
                                                ? "Free"
                                                : `$${data?.cost}`}
                                            </option>
                                          </>
                                        )}
                                      </>
                                    );
                                  }
                                )}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      {DeliveryShippingData?.isVisible && (
                        <>
                          <div className="col-12 ro-font1 mt-5">
                            Delivery Address
                          </div>
                          <div className="row my-4">
                            <div className="col-md-4 col-4 align-self-center">
                              <label>
                                Shipping Method
                                <span className="text-danger"> *</span>
                              </label>
                            </div>
                            <div className="col-md-8 col-8">
                              <div
                                className="d-flex"
                                onChange={(e) =>
                                  SetDeliveryShippingData({
                                    ...DeliveryShippingData,
                                    ShippingMethod: e.target.id,
                                  })
                                }
                              >
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="ShippingRadio"
                                    id="UseBillingAddress"
                                    value={DeliveryShippingData.ShippingMethod}
                                    defaultChecked={
                                      DeliveryShippingData.ShippingMethod ===
                                        "UseBillingAddress" || ""
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="UseBillingAddress"
                                  >
                                    Use my billing address
                                  </label>
                                </div>
                                <div className="ms-2 form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="ShippingRadio"
                                    id="DifferentAddress"
                                    value={DeliveryShippingData.ShippingMethod}
                                    defaultChecked={
                                      DeliveryShippingData.ShippingMethod ===
                                        "DifferentAddress" || ""
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="DifferentAddress"
                                  >
                                    I'd like to enter a different address
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {DeliveryShippingData.ShippingMethod ==
                            "DifferentAddress" && (
                            <>
                              <div className="col-12 my-3">
                                <div className="form-group">
                                  <div className="row">
                                    <div className="col-md-4 col-4 align-self-center">
                                      <label>
                                        Shipping Name
                                        <span className="text-danger"> *</span>
                                      </label>
                                    </div>
                                    <div className="col-md-8 col-8">
                                      <input
                                        className="box w-100"
                                        name="ShippingName"
                                        type="text"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-12 my-3">
                                <div className="form-group">
                                  <div className="row">
                                    <div className="col-md-4 col-4 align-self-center">
                                      <label>
                                        Phone Number
                                        <span className="text-danger"> *</span>
                                      </label>
                                    </div>
                                    <div className="col-md-8 col-8">
                                      <input
                                        className="box w-100"
                                        name="ShippingPhoneNumber"
                                        type="number"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 my-3">
                                <div className="form-group">
                                  <div className="row">
                                    <div className="col-md-4 col-4 align-self-center">
                                      <label>
                                        Address
                                        <span className="text-danger"> *</span>
                                      </label>
                                    </div>
                                    <div className="col-md-8 col-8">
                                      <input
                                        className="box w-100"
                                        name="ShippingStreetAddress"
                                        type="text"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 my-3">
                                <div className="form-group">
                                  <div className="row">
                                    <div className="col-md-4 col-4 align-self-center">
                                      <label>
                                        City{" "}
                                        <span className="text-danger"> *</span>
                                      </label>
                                    </div>
                                    <div className="col-md-8 col-8">
                                      <input
                                        className="box w-100"
                                        name="ShippingCity"
                                        type="text"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {DeliveryShippingData?.ShippingShowState && (
                                <>
                                  <div className="col-12 my-3">
                                    <div className="form-group">
                                      <div className="row">
                                        <div className="col-md-4 col-4 align-self-center">
                                          <label>
                                            State/Province
                                            <span className="text-danger">
                                              {" "}
                                              *
                                            </span>
                                          </label>
                                        </div>
                                        <div className="col-md-8 col-8">
                                          <select
                                            className="form-select select mb-0"
                                            name="ShippingState"
                                            id=""
                                          >
                                            <option value="-1">
                                              Please select
                                            </option>
                                            {DeliveryShippingData?.ShippingStateList?.map(
                                              (data, i) => {
                                                return (
                                                  <>
                                                    <option
                                                      key={data?.StateCode}
                                                      value={data?.StateCode}
                                                    >
                                                      {data?.StateName}
                                                    </option>
                                                  </>
                                                );
                                              }
                                            )}
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              {DeliveryShippingData?.ShippingShowZipCode && (
                                <div className="col-12 my-3">
                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-md-4 col-4 align-self-center">
                                        <label>
                                          ZIP/Postal Code{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                      </div>
                                      <div className="col-md-8 col-8">
                                        <input
                                          className="box w-100"
                                          name="ShippingZipCode"
                                          type="text"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          )}

                          {DeliveryShippingData?.ShippingShowDate && (
                            <div className="col-12 my-3">
                              <div className="form-group">
                                <div className="row">
                                  <div className="col-md-4 col-4 align-self-center">
                                    <label>
                                      The date you will be leaving this address
                                      <span className="text-danger"> *</span>
                                    </label>
                                  </div>
                                  <div className="col-md-8 col-8">
                                    <input
                                      className="fromCalender w-100"
                                      name="ShippingDate"
                                      type="date"
                                      id="fromCalender"
                                      min={
                                        new Date().toISOString().split("T")[0]
                                      }
                                      max={getSmallestDate(
                                        BeginEndDate?.MultipleDatesData
                                      )}
                                      value={BeginEndDate?.DepartureDate}
                                      onChange={(e) =>
                                        setBeginEndDate({
                                          ...BeginEndDate,
                                          DepartureDate: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}

                  {(BundleSubLinkData[0]?.bundles[0]?.optionals.length > 0 ||
                    DeliveryShippingData?.Accessory?.length > 0) &&
                    Equipment.ShowShipping && (
                      <>
                        <div className="col-12 ro-font1 mt-5">Accessories</div>
                        <div className="row mt-4 py-4">
                          <div className="col-12">
                            <label className="mb-3">Options</label>
                            <div className="optionalContainer">
                              <div className="row py-2 mx-0">
                                <div className="col-12">
                                  <div className="row">
                                    <div className="col-2"></div>
                                    <div className="col-4 ro-font2 ">
                                      Optional
                                    </div>
                                    <div className="col-2 ro-font2 ">Rate</div>
                                    <div className="col-2 ro-font2 ">
                                      Insurance
                                    </div>
                                    <div className="col-2 ro-font2 ">
                                      Quantity
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {BundleSubLinkData[0]?.bundles[0]?.optionals.map(
                                (data, i) => {
                                  return (
                                    <>
                                      <div className="row py-3 mx-0 phonecard">
                                        <div className="col-12">
                                          <div className="row align-items-center">
                                            <div className="col-2 text-center">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value=""
                                                id=""
                                                name="ifOptional"
                                                clientcode={data?.ClientCode}
                                                couponcode={data?.CouponCode}
                                                deposit={data?.Deposit}
                                                equipmentcode={
                                                  data?.Equipment_code
                                                }
                                                insurance={data?.Insurance.toString()}
                                                optionalcode={
                                                  data?.OptionalCode
                                                }
                                                optionalfeedesc={data?.Rate}
                                                optionalimg={data?.image}
                                                optionalname={data?.chckBxName}
                                                plancode={data?.Plan_code}
                                                requiredinsurance={data?.Insurance.toString()}
                                                requiredoperationsystem={data?.IsRequiredOperationSystem.toString()}
                                              />
                                            </div>
                                            <div className="col-4 ro-font3 ">
                                              {data?.chckBxName.replace(
                                                "<br/>",
                                                ""
                                              )}
                                            </div>
                                            <div className="col-2 ro-font3 ">
                                              {data?.Rate}
                                            </div>

                                            <div className="col-2 ro-font3 ">
                                              {data?.optRequiredInsurance && (
                                                <input
                                                  className="form-check-input text-center"
                                                  type="checkbox"
                                                  value=""
                                                  id="flexCheckDefault"
                                                  name=""
                                                />
                                              )}
                                            </div>
                                            <div className="col-2 ro-font3 pe-md-5">
                                              <input
                                                className="box w-100 h-100"
                                                name="OptionalQty"
                                                type="number"
                                                defaultValue={1}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                              {DeliveryShippingData?.Accessory?.map(
                                (data, i) => {
                                  return (
                                    <>
                                      <div className="row py-3 mx-0 phonecard">
                                        <div className="col-12">
                                          <div className="row align-items-center">
                                            <div className="col-2 text-center">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value=""
                                                id=""
                                                name="ifOptional"
                                                clientcode={data?.ClientCode}
                                                couponcode={data?.CouponCode}
                                                deposit={data?.Deposit}
                                                equipmentcode={
                                                  data?.Equipment_code
                                                }
                                                insurance={data?.Insurance.toString()}
                                                optionalcode={data?.chckbxID}
                                                optionalfeedesc={data?.rate}
                                                optionalimg={data?.image}
                                                optionalname={data?.chckBxName}
                                                plancode={data?.Plan_code}
                                                requiredinsurance={data?.Insurance.toString()}
                                                requiredoperationsystem={data?.IsRequiredOperationSystem.toString()}
                                              />
                                            </div>
                                            <div className="col-4 ro-font3 ">
                                              {data?.chckBxName.replace(
                                                "<br/>",
                                                ""
                                              )}
                                            </div>
                                            <div className="col-2 ro-font3 ">
                                              {data?.rate}
                                            </div>

                                            <div className="col-2 ro-font3 "></div>
                                            <div className="col-2 ro-font3 pe-md-5">
                                              <input
                                                className="box w-100 h-100"
                                                name="OptionalQty"
                                                type="number"
                                                defaultValue={1}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  <div className="col-12 ro-font1 mt-5">
                    Payment Information
                  </div>
                  <div className="row my-4">
                    <div className="col-md-4 col-4">
                      <label
                        className="form-check-label ro-font2 c142454"
                        htmlFor="flexCheckDefault"
                      >
                        Copy Client Details
                      </label>
                    </div>
                    <div className="col-md-8 col-8">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          name=""
                          onChange={(e) => {
                            CopyDetails(e.target.checked);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-3">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-4 col-4 align-self-center">
                          <label>
                            First Name
                            <span className="text-danger"> *</span>
                          </label>
                        </div>
                        <div className="col-md-8 col-8">
                          <input
                            className="box w-100"
                            name="billingFirstName"
                            type="text"
                            autoComplete="none"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-3">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-4 col-4 align-self-center">
                          <label>
                            Last Name
                            <span className="text-danger"> *</span>
                          </label>
                        </div>
                        <div className="col-md-8 col-8">
                          <input
                            className="box w-100"
                            name="billingLastName"
                            type="text"
                            autoComplete="none"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-3">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-4 col-4 align-self-center">
                          <label>
                            Cardholder Email
                            <span className="text-danger"> *</span>
                          </label>
                        </div>
                        <div className="col-md-8 col-8">
                          <input
                            className="box w-100"
                            name="billingEmail"
                            type="text"
                            autoComplete="none"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-3">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-4 col-4 align-self-center">
                          <label>
                            Card Type
                            <span className="text-danger"> *</span>
                          </label>
                        </div>
                        <div className="col-md-8 col-8">
                          <select
                            className="form-select select mb-0"
                            name="billingCardType"
                            id=""
                          >
                            <option value="0">Please select</option>
                            <option value="Amex">Amex</option>
                            <option value="Discover">Discover</option>
                            <option value="Mastercard">Mastercard</option>
                            <option value="Visa">Visa</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-3">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-4 col-4 align-self-center">
                          <label>
                            Card Number
                            <span className="text-danger"> *</span>
                          </label>
                        </div>
                        <div className="col-md-8 col-8">
                          <input
                            className="box w-100"
                            name="billingCardNumber"
                            type="type"
                            value={card?.replace(/(\d{4})(?=\d)/g, "$1 ")}
                            onChange={(e) =>
                              e.target.value.length <= 19 &&
                              setCard(e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 my-3">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-4 col-4 align-self-center">
                          <label>
                            Expiration Date
                            <span className="text-danger"> *</span>
                          </label>
                        </div>
                        <div className="col-md-8 col-8">
                          <div className="row">
                            <div className="col-6">
                              <select
                                className="form-select select mb-0"
                                name="billingExpireMonth"
                                id=""
                              >
                                <option value="0">Select Month</option>
                                <option value="01">1</option>
                                <option value="02">2</option>
                                <option value="03">3</option>
                                <option value="04">4</option>
                                <option value="05">5</option>
                                <option value="06">6</option>
                                <option value="07">7</option>
                                <option value="08">8</option>
                                <option value="09">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                              </select>
                            </div>
                            <div className="col-6">
                              <select
                                className="form-select select mb-0"
                                name="billingExpireYear"
                                id=""
                              >
                                <option value="0">Select Year</option>
                                {Year.map((yr, i) => (
                                  <>
                                    <option
                                      key={i}
                                      value={yr.toString().substring(2)}
                                    >
                                      {yr}
                                    </option>
                                  </>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-3">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-4 col-4 align-self-center">
                          <label>Coupon/Voucher Code(not required)</label>
                        </div>
                        <div className="col-md-8 col-8">
                          <input
                            className="box w-100"
                            name="coupon"
                            type="text"
                            autoComplete="none"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-3">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-4 col-4 align-self-center">
                          <label>Optional comments </label>
                        </div>
                        <div className="col-md-8 col-8">
                          <textarea className="box w-100" name="ccNote" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 ro-font1 mt-5">Authorization</div>
                  <div className="row mb-4 py-4">
                    <div className="col-md-12">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          name="authorizationCheckbox"
                        />
                        <div
                          className="form-check-label ro-font2 text-danger"
                          htmlFor="flexCheckDefault"
                        >
                          I hereby agree to the Terms and Conditions of the
                          service. I understand rates are only guaranteed when I
                          follow the dialing directions packaged with my rental.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-12">
                      {ErrorMsg?.err_visible && (
                        <>
                          <span className="text-danger">
                            {ErrorMsg?.message}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="row mt-4 mb-5">
                    <div className="col-md-4">
                      <button
                        type="submit"
                        className="btn ro-button btn-d-flex py-2 w-100 btn0"
                      >
                        Place Your Order!
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </form>
      </div>
      <LPPopup show={show}>
        <POPopup successData={successData} setShow={setShow} />
      </LPPopup>
    </React.Fragment>
  );
}
